/**
 * gfm.io website theme
 */

import { Interpolation } from "@emotion/css";
import { FontList, MediaQuery, px, rem, Selectors, systemFonts, vh, vw } from "@service-ui/mixins";
import FontWeight from "./FontWeight";

/**
 * clrs.cc colors
 */

export const navy = "#001f3f";
export const blue = "#0074d9";
export const aqua = "#7fdbff";
export const teal = "#39cccc";
export const olive = "#3d9970";
export const green = "#2ecc40";
export const lime = "#01ff70";
export const yellow = "#ffdc00";
export const orange = "#ff851b";
export const red = "#ff4136";
export const maroon = "#85144b";
export const fuchsia = "#f012be";
export const purple = "#b10dc9";
export const black = "#111111";
export const gray = "#aaaaaa";
export const silver = "#dddddd";
export const white = "#ffffff";

export const clrsCcColors = {
  navy,
  blue,
  aqua,
  teal,
  olive,
  green,
  lime,
  yellow,
  orange,
  red,
  maroon,
  fuchsia,
  purple,
  black,
  gray,
  silver,
  white,
};

class Theme {
  public baseLineHeight = 1.5;
  public colors = {
    ...clrsCcColors,
    ink1: `rgb(19, 22, 25)`,
    paper1: `rgb(239, 241, 244)`,
  };

  public fonts = {
    sans: FontList(
      "SF Pro Text",
      "Source Sans Pro",
      "Source Han Sans",
      "Source Han Sans JP",
      "Source Han Sans SC",
      "Source Han Sans CN",
      "Source Han Sans TC",
      "Source Han Sans TW",
      "Source Han Sans K",
      "Source Han Sans KR",
      ...systemFonts,
    ),
  };

  public fontSizes = () => ({
    tiny: { fontSize: rem(0.6), lineHeight: this.scale(0.75) },
    // tslint:disable-next-line:object-literal-sort-keys
    small: { fontSize: rem(0.8), lineHeight: this.scale(1) },
    regular: { fontSize: rem(1), lineHeight: this.scale(1) },
    default: { fontSize: rem(1), lineHeight: this.scale(1) },
    lead: { fontSize: rem(1.2), lineHeight: this.scale(1.25) },
    large: { fontSize: rem(1.3), lineHeight: this.scale(1.5) },
    xlarge: { fontSize: rem(1.6), lineHeight: this.scale(1.75) },
    xxlarge: { fontSize: rem(2.0), lineHeight: this.scale(2) },
    display: { fontSize: rem(2.5), lineHeight: this.scale(2.5) },
    xdisplay: { fontSize: rem(3), lineHeight: this.scale(3) },
    xxdisplay: { fontSize: rem(4), lineHeight: this.scale(4) },
    xxxdisplay: { fontSize: rem(5), lineHeight: this.scale(5) },
  });

  public scale = (n: number) => rem(n * this.baseLineHeight);
  public global = (): Interpolation => ({
    html: {
      // prettier-ignore
      [MediaQuery.screen(
          MediaQuery.Condition.minWidth( px(minWidth)),
          MediaQuery.Condition.minHeight( px(minHeight)),
        )]: {
          fontSize: tallScreenScalingFontSize,
        },
      [MediaQuery.screen(
        MediaQuery.Condition.minWidth(px(minWidth)),
        MediaQuery.Condition.minHeight(px(minHeight)),
        MediaQuery.Condition.minAspectRatio(optWidth, optHeight),
      )]: {
        fontSize: wideScreenScalingFontSize,
      },
    },

    body: {
      WebkitTextSizeAdjust: "none",
      lineHeight: this.baseLineHeight,
    },

    [Selectors.HasAttributeWithValue("lang", "jp")]: {
      fontFamily: FontList("Source Han Sans JP", "Source Han Sans", "Source Sans Pro", ...systemFonts),
      fontVariantEastAsian: "full-width",
      textCombineUpright: "digits 4",
      textOrientation: "upright",
    },
  });

  public themed = (name: string) => {
    switch (name) {
      case "Heading":
        return () => ({ fontWeight: FontWeight.Bold });
      case "H1":
        return () => this.fontSizes().xxlarge;
      case "H2":
        return () => this.fontSizes().xlarge;
      case "H3":
        return () => this.fontSizes().xlarge;
      default:
        return () => ({});
    }
  };
}

const optLines = 40;
const optChars = 66;
const charsPerEm = 2;
const horizontalProportion = 1 / 1.618;
const lineHeight = 1.5;

const optHeight = optLines * lineHeight;
const optWidth = optChars / charsPerEm / horizontalProportion;

const baseFontSize = 16;

const minWidth = baseFontSize * optWidth;
const minHeight = baseFontSize * optHeight;

const wideScreenScalingFontSize = vh(100 / optHeight);
const tallScreenScalingFontSize = vw(100 / optWidth);

export default Theme;
