/**
 * gfm.io website entrypoint
 */

import { Global, jsx } from "@emotion/core";
import { cssReset, fillScreenFlexReset, fillScreenReset, systemFontReset } from "@service-ui/mixins";
import { ThemeProvider } from "emotion-theming";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "react-error-boundary";

import App from "./App";
import Theme from "./theme";

const theme = new Theme();

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Global styles={cssReset} />
        <Global styles={fillScreenReset()} />
        <Global styles={fillScreenFlexReset()} />
        <Global styles={systemFontReset(theme.fonts.sans)} />
        <Global styles={theme.global()} />
        <App />
      </ThemeProvider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById("root"),
);
