/**
 * Main application component
 */

import { hot } from "react-hot-loader";

import { jsx } from "@emotion/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "./components/Layout";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

const App = () => (
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route path="/" exact={true} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  </BrowserRouter>
);

export default hot(module)(App);
