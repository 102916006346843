/**
 * Not found page
 */

import { jsx } from "@emotion/core";
import { ThemedProps } from "@service-ui/mixins";
import Box from "@service-ui/react/Box";
import Flex from "@service-ui/react/Flex";
import Heading from "@service-ui/react/Heading";
import List from "@service-ui/react/List";
import SafeArea from "@service-ui/react/SafeArea";
import { withTheme } from "emotion-theming";
import { Link as RouterLink } from "react-router-dom";
import Link from "../components/Link";
import Routes from "../Routes";
import Theme from "../theme";

const NotFound = withTheme(({ theme }: ThemedProps<Theme>) => (
  <Flex.Column as="article" css={{ width: "100%" }} bg={theme!.colors.paper1} c={theme!.colors.ink1}>
    <Flex.Column flex={1} px={1} as="header" css={{ width: "100%" }}>
      <SafeArea.X>
        <Box>
          <Heading.H1 as="h1">Not found</Heading.H1>
        </Box>
      </SafeArea.X>
    </Flex.Column>
    <Flex p={1} alignSelf="flex-end" justifyContent="stretch" css={{ textAlign: "right" }}>
      <SafeArea.X>
        <List>
          <List.Item>
            <Link to={Routes.Home}>Home</Link>
          </List.Item>
        </List>
      </SafeArea.X>
    </Flex>
  </Flex.Column>
));

export default NotFound;
