/**
 * Layout
 */

import { jsx } from "@emotion/core";
import Flex from "@service-ui/react/Flex";
import Text from "@service-ui/react/Text";
import React from "react";
import Container from "./Container";

const Layout: React.FC = ({ children }) => (
  <Flex.Column flex={1}>
    {/* <Flex.Row as="header" bg="#111" c="#FFF" p={1}>
      <Container>
        <Text>Header</Text>
      </Container>
    </Flex.Row> */}
    <Flex flex={1}>{children}</Flex>
    {/* <Flex.Row as="footer" bg="#111" c="#FFF" p={1}>
      <Container>
        <Text>Footer</Text>
      </Container>
    </Flex.Row> */}
  </Flex.Column>
);

export default Layout;
