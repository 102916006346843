/**
 * Link
 */

import styled, { StyledComponent } from "@emotion/styled";
import { Selectors, ThemedProps } from "@service-ui/mixins";
import { ClassAttributes } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import Theme from "../theme";
import FontWeight from "../theme/FontWeight";

const linkStyles = [
  {
    fontWeight: FontWeight.SemiBold as any,
    textDecoration: "none",
  },
  ({ theme }: ThemedProps<Theme>) => ({
    [Selectors.Pseudo.Hover]: {
      color: theme!.colors.blue,
    },
    [Selectors.Pseudo.Active]: {
      color: theme!.colors.blue,
    },
    [Selectors.Pseudo.Focus]: {
      color: theme!.colors.blue,
    },
  }),
];

const Link: StyledComponent<LinkProps & ClassAttributes<RouterLink>, ThemedProps<Theme>, any> & {
  External: typeof ExternalLink;
} = styled(RouterLink)(linkStyles) as any;

const ExternalLink = styled("a")(linkStyles);
ExternalLink.defaultProps = {
  target: "_blank",
};

Link.External = ExternalLink;

export default Link;
