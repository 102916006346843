/**
 * Home page
 */

import { Interpolation, jsx } from "@emotion/core";
import { withTheme } from "emotion-theming";
import React from "react";

import styled from "@emotion/styled";
import Box from "@service-ui/react/Box";
import Flex from "@service-ui/react/Flex";
import Heading from "@service-ui/react/Heading";
import List from "@service-ui/react/List";
import Paragraph from "@service-ui/react/Paragraph";
import SafeArea from "@service-ui/react/SafeArea";
import Text from "@service-ui/react/Text";

import Container from "../components/Container";
import Link from "../components/Link";
import Theme from "../theme";

import { Selectors } from "@service-ui/mixins";
// import lipsums from "i18n-lipsum";
import FontWeight from "../theme/FontWeight";

// for (const lipsum of lipsums) {
//   console.log(lipsum);
// }

const Home = withTheme(({ theme }: { theme: Theme }) => (
  <Flex.Column as="article" css={{ width: "100%" }} bg={theme.colors.paper1} c={theme.colors.ink1}>
    <Flex.Column flex={1} px={1} as="header" css={{ width: "100%" }}>
      <SafeArea.X>
        <Box>
          {/* <Container> */}
          <Heading.H1 as="h1">Frédérique Mittelstaedt</Heading.H1>
          {/* <Paragraph></Paragraph> */}
          {/* </Container> */}
        </Box>
      </SafeArea.X>
    </Flex.Column>
    <Flex p={1} alignSelf="flex-end" justifyContent="stretch" css={{ textAlign: "right" }}>
      <SafeArea.X>
        <List>
          <List.Item>
            <Link.External href="https://github.com/gfmio">GitHub</Link.External>
          </List.Item>
          <List.Item>
            <Link.External href="https://twitter.com/gfmio">Twitter</Link.External>
          </List.Item>
          <List.Item>
            <Link.External href="https://www.linkedin.com/in/gfmio">LinkedIn</Link.External>
          </List.Item>
          <List.Item>
            <Link.External href="https://angel.co/gfmio">AngelList</Link.External>
          </List.Item>
          <List.Item>
            <Link.External href="mailto:hi@gfm.io">Contact</Link.External>
          </List.Item>
        </List>
      </SafeArea.X>
    </Flex>
    {/* <Box bg={theme.colors.paper1} c={theme.colors.ink1} px={1} as="header" css={{ width: "100%" }}>
      <Container>
        <Heading.H1>
          <Text c={theme.colors.white}>Test</Text> <Text c={theme.colors.paper1}>Test</Text>{" "}
          <Text c={theme.colors.ink1}>Test</Text> <Text c={theme.colors.black}>Test</Text>{" "}
          <Text c={theme.colors.red}>Test</Text>
        </Heading.H1>
        <Paragraph css={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
          abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz
        </Paragraph>
        <Paragraph lang="jp">
          任択え近職ざ庄2点ろ貧準含タソケロ変権活ミ追稿ヌサニ主山鈴ユヌ課件ンぽせ験感シネ平日そリ克活ヨウ他記チ者感赤比幻しまくー。外もぎそぼ詳入どのま玉崎げき随違いッほは払点件くれぱ来社反ネ国本ムハナ要開モ河高をもゃイ謝属ロチア否無マ際界毎せご技楽らをもた住格ハニヒ供金ちほい者宏紛誓靖をらめ。
        </Paragraph>
        <Paragraph lang="jp" css={{ writingMode: "vertical-rl", maxHeight: "17rem" }}>
          任択え近職ざ庄2点ろ貧準含タソケロ変権活ミ追稿ヌサニ主山鈴ユヌ課件ンぽせ験感シネ平日そリ克活ヨウ他記チ者感赤比幻しまくー。外もぎそぼ詳入どのま玉崎げき随違いッほは払点件くれぱ来社反ネ国本ムハナ要開モ河高をもゃイ謝属ロチア否無マ際界毎せご技楽らをもた住格ハニヒ供金ちほい者宏紛誓靖をらめ。
        </Paragraph>
      </Container>
    </Box> */}
    {/* <Box px={1}>
      <Container>
        {lipsums.map((lipsum: any, index: number) => (
          <React.Fragment key={index}>
            <Heading.H3>{lipsum.languages.map((l: any) => l.name).join(" / ")}</Heading.H3>
            {(Array.isArray(lipsum.loremIpsum) ? lipsum.loremIpsum : [lipsum.loremIpsum]).map((item: any) => (
              <Paragraph lang={lipsum.languages[0].code} dir={lipsum.languages[0].rtl ? "rtl" : "ltr"}>
                {console.log(item)}
                {typeof item === "string" ? item : item.content}
              </Paragraph>
            ))}
          </React.Fragment>
        ))}
        <Paragraph>Content...</Paragraph>
      </Container>
    </Box> */}
    {/* <Box bg={theme.colors.blue} px={1} as="footer">
      <Container>
        <Paragraph>Footer...</Paragraph>
      </Container>
    </Box> */}
  </Flex.Column>
));

export default Home;
