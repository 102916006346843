/**
 * Font weight named values
 */

enum FontWeight {
  Thin = 100,
  Hairline = 100,
  ExtraLight = 200,
  UltraLight = 200,
  Light = 300,
  Normal = 400,
  Book = 400,
  Medium = 500,
  SemiBold = 600,
  DemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  UltraBold = 800,
  Black = 900,
  Heavy = 900,
}

export default FontWeight;
